import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';
import { ErrorInfo } from 'react';

export function initializeSentry() {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: 'pawlytics@' + process.env.GIT_SHA,
    environment: 'production',
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    blacklistUrls: [
      'http://localhost:3000/',
      'http://pawlytics.ngrok.io/',
      'https://pawlytics.ngrok.io/',
    ],
  });
}

export function apiApolloErrorTrap(error: ApolloError) {
  error.graphQLErrors.forEach((err) => apiGraphqlErrorTrap(err));
}

export function apiGraphqlErrorTrap(error: GraphQLError) {
  Sentry.configureScope((scope) => scope.setLevel(Severity.Error));
  Sentry.captureMessage(error.message, {
    tags: {
      locations: error.locations?.join(', '),
      path: error.path?.join(', '),
    },
  });
}

export function apiNetworkErrorTrap(error: Error) {
  Sentry.configureScope((scope) => scope.setLevel(Severity.Error));
  Sentry.captureException(error);
}

export function captureBoundaryException(error: Error, errorInfo: ErrorInfo) {
  Sentry.configureScope((scope) => {
    Object.entries(errorInfo).forEach(([key, value]) =>
      scope.setExtra(key, value)
    );
  });
  Sentry.captureException(error);
}

export function setSentryUser(email: string) {
  Sentry.configureScope((scope) => {
    scope.setUser({ email });
  });
}
